import * as React from "react";
import { ChangeEvent, UIEvent, useState } from "react";
import { PrimaryButton } from "../lib/buttons";
import "./SelectIdentityProvider.css";

const lastUsedSSOEmail = "lastUsedSSOEmail";

export function SelectIdentityProvider() {
    const [email, setEmail] = useState(() => localStorage.getItem(lastUsedSSOEmail) ?? "");

    const logInWithSso = () => {
        localStorage.setItem(lastUsedSSOEmail, email);
        window.location.href = `/api/login/start?idpEmail=${email}`;
    };

    return (
        <div className="selectIdentityProvider">
            <h1 className="selectIdentityProviderTitle">Logg inn med SSO</h1>
            <p className="selectIdentityProviderIngress">
                Skriv inn epostadressen hos din organisasjon for å logge inn med single sign-on (SSO). Hvis din
                organisasjon ikke har avtale med BarentsWatch så må du benytte ID-porten for innlogging.
            </p>
            <input
                type="email"
                placeholder="Epost"
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        logInWithSso();
                    }
                }}
                value={email}
            />

            <PrimaryButton type="button" onClick={logInWithSso} className="selectIdentityProviderLoginButton">
                Logg inn
            </PrimaryButton>
        </div>
    );
}
