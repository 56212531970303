import * as React from "react";
import * as ReactDOM from "react-dom";
import { Route, Switch } from "react-router";
import { HashRouter } from "react-router-dom";
import { ApplicationSelect } from "./ApplicationSelect";
import { UserInfoDto } from "./generated";
import { Welcome } from "./Welcome";
import { SelfRegistration } from "./userManager/selfRegistration";

import "./assets/style.css";
import "./assets/button.css";
import "@bw/bw-components/dist/bw-components.css";
import { useUserinfo } from "./useUserinfo";
import { RegisterCredential } from "./userManager/registerCredential";
import { GetCredential } from "./userManager/getCredential";
import { ErrorPage } from "./error";
import { BarentswatchIcon } from "@bw/bw-components";
import { SelectIdentityProvider } from "./SelectIdentityProvider/SelectIdentityProvider";

function AutenticatedApplication({ userinfo }: { userinfo: UserInfoDto }) {
    return (
        <div className="main">
            <HashRouter>
                <Switch>
                    <Route path={"/accept-invitation"}>
                        <SelfRegistration />
                    </Route>
                    <Route path={"/register-credential"}>
                        <RegisterCredential userinfo={userinfo} />
                    </Route>
                    <Route path={"/get-credential"}>
                        <GetCredential />
                    </Route>
                    <Route>
                        <ApplicationSelect userinfo={userinfo} />
                    </Route>
                </Switch>
            </HashRouter>
        </div>
    );
}

export function UnauthenticatedApplication() {
    return (
        <div className="main">
            <HashRouter>
                <Switch>
                    <Route path={"/get-credential"}>
                        <GetCredential />
                    </Route>
                    <Route path={"/select-identity-provider"}>
                        <SelectIdentityProvider />
                    </Route>
                    <Route>
                        <Welcome />
                    </Route>
                </Switch>
            </HashRouter>
        </div>
    );
}

function App() {
    const { userinfo, loading, error } = useUserinfo();
    if (error) {
        return <ErrorPage />;
    }
    return (
        <div id="app">
            <header>
                {userinfo && (
                    <>
                        {userinfo && userinfo.fullName ? (
                            <div className="userName">{userinfo && userinfo.fullName}</div>
                        ) : (
                            <div className={"headerLogo"}>
                                <BarentswatchIcon className="logo" />
                            </div>
                        )}

                        <a className={"logout"} href="/api/login/endsession">
                            Logg ut
                        </a>
                    </>
                )}
            </header>
            <main id="appMain">
                {loading ? (
                    <div />
                ) : userinfo ? (
                    <AutenticatedApplication userinfo={userinfo} />
                ) : (
                    <UnauthenticatedApplication />
                )}
            </main>
            <footer />
        </div>
    );
}

ReactDOM.render(<App />, document.getElementById("appRoot"));
