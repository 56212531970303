import * as React from "react";
import { LoginApi, UserInfoDto } from "../generated";
import { encode } from "base64-arraybuffer";
import { LogoIcon, useSubmit } from "@bw/bw-components";
import { ErrorView, UserError } from "../lib/errorView";
import { OutlinedPrimaryButton, PrimaryButton, SecondaryButton } from "../lib/buttons";

export function RegisterCredential({ userinfo }: { userinfo: UserInfoDto }) {
    const loginApi = new LoginApi();

    async function createCredential() {
        const options: CredentialCreationOptions = {
            publicKey: {
                rp: {
                    name: "BarentsWatch",
                    id: window.location.hostname,
                },
                user: {
                    name: userinfo.personId,
                    displayName: userinfo.fullName,
                    id: new TextEncoder().encode(userinfo.personId),
                },
                challenge: new TextEncoder().encode(userinfo.personId),
                //New version in chrome causes the browser to crash if this is not present.
                authenticatorSelection: {},
                pubKeyCredParams: [{ alg: -7, type: "public-key" }],
                attestation: "direct",
            },
        };
        try {
            return (await navigator.credentials.create(options)) as PublicKeyCredential;
        } catch (e: any) {
            if (e.name === "NotAllowedError") {
                throw new UserError("Registreringen ble avbrutt av bruker eller tidsavbrudd");
            }
            throw e as Error;
        }
    }

    async function registerCredential() {
        const credential = await createCredential();
        const credentialId = credential.id;
        const clientDataJSON = encode(credential.response.clientDataJSON);
        const attestationObject = encode((credential.response as AuthenticatorAttestationResponse).attestationObject);
        await loginApi.registerCredential({
            authenticationCredentialDto: { credentialId, clientDataJSON, attestationObject },
        });
        document.cookie = `credentialId=${credentialId};max-age=${60 * 60 * 24 * 365}`;
    }

    async function handleAskLater() {
        window.location.href = "/api/login/redirect?skipWebauthn=true";
    }

    const {
        handleSubmit: handleRegister,
        updateError,
        updating,
    } = useSubmit(async () => {
        await registerCredential();
        window.location.href = "/api/login/redirect?skipWebauthn=true";
    });

    const {
        handleSubmit: handleDontAskAgain,
        updateError: errorDontAskAgain,
        updating: updatingDontAsk,
    } = useSubmit(async () => {
        await loginApi.refuseWebauthn();
        window.location.href = "/api/login/redirect";
    });

    return (
        <>
            <LogoIcon className="logo" />
            <div className={"bw-header"}>
                <div>Registrer mobilen din</div>
                <div>for å kunne logge inn med skjermlås i fremtiden</div>
            </div>
            <ErrorView error={errorDontAskAgain} />
            <ErrorView error={updateError} />

            <div className={"button-group"}>
                <PrimaryButton onClick={handleRegister} disabled={updating}>
                    Registrer mobil
                </PrimaryButton>
                <OutlinedPrimaryButton onClick={handleAskLater}>Spør meg senere</OutlinedPrimaryButton>
                <SecondaryButton onClick={handleDontAskAgain} disabled={updatingDontAsk}>
                    Ikke spør meg igjen
                </SecondaryButton>
            </div>
        </>
    );
}
