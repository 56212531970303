import * as React from "react";
import { useContext, useState } from "react";
import { InvitationDto } from "../generated";
import { NotFoundError } from "../generated/base";
import { ErrorView } from "../lib/errorView";
import { LoginApiContext } from "./LoginApiContext";
import { Form, FormInput, FormSelect, Loader, useLoader, useSubmit } from "@bw/bw-components";
import { OutlinedPrimaryButton, PrimaryButton } from "../lib/buttons";

export function SelfRegistration() {
    function invitationCode(): string | undefined {
        const questionPos = window.location.hash.indexOf("?");
        if (questionPos) {
            return new URLSearchParams(window.location.hash.substring(questionPos)).get("invitation_code") ?? undefined;
        }
    }

    const { userManagerApi } = useContext(LoginApiContext);
    const {
        data: invitation,
        loading,
        error,
        reload,
    } = useLoader<InvitationDto>(
        async () => await userManagerApi.fetchInvitation({ pathParams: { invitationCode: invitationCode() ?? "" } }),
        []
    );
    if (error instanceof NotFoundError) {
        window.location.href = "/";
    }

    return (
        <>
            {loading && <Loader />}
            {error && <ErrorView error={error} message={"Kunne ikke laste invitasjon."} reload={reload} />}
            {invitation && <AcceptInvitationForm invitation={invitation} onComplete={reload} />}
        </>
    );
}

function AcceptInvitationForm({ invitation, onComplete }: Readonly<{ invitation: InvitationDto; onComplete(): void }>) {
    if (invitation.isPhoneNumberValidated) {
        return <CompleteRegistationForm invitation={invitation} />;
    } else if (invitation.isPhoneConfirmationCodeSent) {
        return <ConfirmSmsForm invitation={invitation} onComplete={onComplete} />;
    } else {
        return <SendSmsForm invitation={invitation} onComplete={onComplete} />;
    }
}

function SendSmsForm({ invitation, onComplete }: Readonly<{ invitation: InvitationDto; onComplete(): void }>) {
    const { userManagerApi } = useContext(LoginApiContext);
    const { handleSubmit, updating, updateError } = useSubmit(async () => {
        await userManagerApi.sendPhoneVerificationCode({ pathParams: { invitationId: invitation.id! } });
    }, onComplete);
    return (
        <>
            {updateError && <ErrorView error={updateError} message={"Kunne ikke sende verifikasjonskode."} />}
            <p>Du har blitt invitert til {invitation.applicationTitle}.</p>
            <p>
                Før du kan fullføre din registrering må du skrive inn en kode som sendes til SMS til det telefonnummeret
                du har blitt registrert med
            </p>
            <p>
                <PrimaryButton onClick={handleSubmit} disabled={updating}>
                    Send kode på SMS
                </PrimaryButton>
            </p>
            {updating && <Loader />}
        </>
    );
}

function ConfirmSmsForm({ invitation, onComplete }: Readonly<{ invitation: InvitationDto; onComplete(): void }>) {
    const [phoneVerificationCode, setPhoneVerificationCode] = useState("");
    const { userManagerApi } = useContext(LoginApiContext);
    const { handleSubmit, updating, updateError } = useSubmit(async () => {
        await userManagerApi.verifyPhoneNumber({
            pathParams: { invitationId: invitation.id! },
            formParams: { phoneVerificationCode },
        });
    }, onComplete);
    const {
        handleSubmit: resendVerificationCode,
        updating: updatingCode,
        updateError: updateErrorCode,
    } = useSubmit(async () => {
        await userManagerApi.sendPhoneVerificationCode({ pathParams: { invitationId: invitation.id! } });
    }, onComplete);
    return (
        <Form onSubmit={handleSubmit} disabled={updating || updatingCode}>
            <h2>Bekreft telefonnummer</h2>
            {updateError && <ErrorView error={updateError} message={"Kunne ikke verifisere telefonnummeret."} />}
            {updateErrorCode && <ErrorView error={updateErrorCode} message={"Kunne ikke sende verifikasjonskode."} />}
            {updating || updatingCode || (
                <FormInput
                    value={phoneVerificationCode}
                    label={"Bekreftelseskode sendt på SMS til " + invitation.mobilePhone}
                    onChangeValue={setPhoneVerificationCode}
                />
            )}
            <div className="submit-and-cancel">
                <OutlinedPrimaryButton onClick={resendVerificationCode}>Send ny kode</OutlinedPrimaryButton>
                <PrimaryButton type={"submit"} data-testid="submitButton" disabled={updating}>
                    Bekreft
                </PrimaryButton>
            </div>
        </Form>
    );
}

function CompleteRegistationForm({ invitation }: Readonly<{ invitation: InvitationDto }>) {
    const { userManagerApi } = useContext(LoginApiContext);
    const redirect = () => (window.location.href = "/");
    const { handleSubmit, updating, updateError } = useSubmit(async () => {
        await userManagerApi.completeRegistration({
            pathParams: { invitationId: invitation.id! },
            formParams: { givenName, familyName, title, dutystationId: selectedDutystationId ?? "" },
        });
    }, redirect);
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [title, setTitle] = useState(invitation.title ?? "");
    const [selectedDutystationId, setSelectedDutystationId] = useState<string | undefined>(undefined);

    const { data } = useLoader(async () => {
        const dutystations = await userManagerApi.getDutystationsForSubscriptions({
            queryParams: { subscriptionIds: [invitation.subscriptionId] },
        });
        return { dutystations };
    }, [invitation]);

    return (
        <Form onSubmit={handleSubmit} disabled={updating}>
            <h2>Fullfør registreringen for {invitation.applicationTitle}</h2>
            {updateError && <ErrorView error={updateError} message={"Kunne ikke fullføre registreringen."} />}
            <FormInput value={givenName} label={"Fornavn"} onChangeValue={setGivenName} autoFocus={true} />
            <FormInput value={familyName} label={"Etternavn"} onChangeValue={setFamilyName} />
            <FormInput value={title} label={"Tittel"} onChangeValue={setTitle} />
            {data?.dutystations && data.dutystations.length > 0 && (
                <FormSelect
                    label={"Tjenestested"}
                    testid={"dutystationSelect"}
                    onChangeValue={(value) => {
                        setSelectedDutystationId(value);
                    }}
                    value={data?.dutystations?.find((rd) => rd.id === selectedDutystationId)?.id ?? ""}
                    options={data?.dutystations ?? []}
                    optionValue={(s) => s.id}
                    optionLabel={(s) => s.name}
                />
            )}
            <div style={{ marginTop: "2em" }}>
                <PrimaryButton type={"submit"} disabled={updating}>
                    Bekreft
                </PrimaryButton>
            </div>
            {updating && <Loader />}
        </Form>
    );
}
