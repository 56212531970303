import * as React from "react";
import { UserManagerApi, UserManagerApiInterface } from "../generated";

interface LoginApis {
    userManagerApi: UserManagerApiInterface;
}

export const LoginApiContext = React.createContext<LoginApis>({
    userManagerApi: new UserManagerApi(),
});
