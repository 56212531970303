import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Form, FormEmail, FormInput, FormPhone, Loader, WarningIcon } from "@bw/bw-components";
import { LoginApiContext } from "../LoginApiContext";
import { SmsInvitationDto, SmsInvitationRegistrationDto, UserInfoDto } from "../../generated";
import { ErrorView, UserError } from "../../lib/errorView";
import { CancelButton, OutlinedPrimaryButton, PrimaryButton } from "../../lib/buttons";
import { RequestError } from "../../generated/base";

export function SmsSelfRegistration({ userinfo }: { userinfo: UserInfoDto }) {
    const { userManagerApi } = useContext(LoginApiContext);
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState<Error | undefined>(undefined);
    const [validationErrorMessage, setValidationErrorMessage] = useState<JSX.Element | string | undefined>(undefined);
    const [smsInvitation, setSmsInvitation] = useState<SmsInvitationDto | undefined>(undefined);
    const { clientApplication } = userinfo;

    useEffect(() => {
        if (smsInvitation?.isCompleted) {
            window.location.href = "/api/login/redirect";
        }
    }, [smsInvitation]);

    function clearErrorMessages() {
        setUpdateError(undefined);
        setValidationErrorMessage(undefined);
    }

    function handleError(error: Error) {
        if (error instanceof RequestError && error.response.status === 400) {
            setValidationErrorMessage(error.body?.message || "En feil har inntruffet.");
        } else {
            setUpdateError(error);
        }
    }

    function getMissingInvitationMessage() {
        if (clientApplication?.name === "mobil") {
            return (
                <>
                    <p>Dette nummeret er ikke knyttet til en bruker som har tilgang til denne appen.</p>
                    <p>
                        Dersom du ønsker å bruke tjenestene FRR Varsling, Felles aksjonsstøtte eller oppdatere din
                        beredskaps- eller redningsressurs, må du kontakte din organisasjon for å få riktig tilgang.
                    </p>
                </>
            );
        } else {
            let clientApplicationName;
            if (clientApplication?.name === "aksjon") {
                clientApplicationName = "Felles aksjonsstøtte";
            } else if (clientApplication?.name === "varsling") {
                clientApplicationName = "FRR Varsling";
            } else if (clientApplication?.title) {
                clientApplicationName = clientApplication.title;
            } else {
                return undefined;
            }
            return (
                <>
                    <p>Dette nummeret er ikke knyttet til en bruker som har tilgang til {clientApplicationName}.</p>
                    <p>
                        Dersom du ønsker å bruke tjenesten {clientApplicationName}, må du kontakte din organisasjon for
                        å få riktig tilgang.
                    </p>
                </>
            );
        }
    }

    async function handleSubmitSearchSmsInvitation(phonenumber: string) {
        setUpdating(true);
        clearErrorMessages();
        try {
            const invitation = await userManagerApi.findSmsInvitation({ queryParams: { phonenumber } });
            if (!invitation.isPhoneNumberVerified) {
                await userManagerApi.smsInvitationSendVerificationCode({
                    pathParams: { smsInvitationId: invitation.id },
                });
            }
            setSmsInvitation(invitation);
        } catch (error) {
            if (error instanceof RequestError && error.response.status === 404) {
                const missingInvitationMessage = getMissingInvitationMessage();
                if (missingInvitationMessage) {
                    setValidationErrorMessage(missingInvitationMessage);
                } else {
                    handleError(error);
                }
            } else {
                handleError(error as Error);
            }
        } finally {
            setUpdating(false);
        }
    }

    async function resendVerificationCode() {
        setUpdating(true);
        clearErrorMessages();
        try {
            await userManagerApi.smsInvitationSendVerificationCode({
                pathParams: { smsInvitationId: smsInvitation!.id },
            });
            setSmsInvitation(
                await userManagerApi.fetchSmsInvitation({
                    pathParams: { smsInvitationId: smsInvitation!.id },
                })
            );
        } catch (error) {
            handleError(error as Error);
        } finally {
            setUpdating(false);
        }
    }

    async function handleSubmitVerificationCode(phoneVerificationCode: string) {
        setUpdating(true);
        clearErrorMessages();
        try {
            await userManagerApi.smsInvitationVerifyCode({
                pathParams: { smsInvitationId: smsInvitation!.id },
                queryParams: { phoneVerificationCode },
            });
            setSmsInvitation(
                await userManagerApi.fetchSmsInvitation({
                    pathParams: { smsInvitationId: smsInvitation!.id },
                })
            );
        } catch (error) {
            handleError(error as Error);
        } finally {
            setUpdating(false);
        }
    }

    async function handleCompleteRegistration(smsInvitationRegistrationDto: SmsInvitationRegistrationDto) {
        setUpdating(true);
        clearErrorMessages();
        try {
            await userManagerApi.smsInvitationCompleteRegistration({
                pathParams: { smsInvitationId: smsInvitation!.id },
                smsInvitationRegistrationDto,
            });
            setSmsInvitation(
                await userManagerApi.fetchSmsInvitation({
                    pathParams: { smsInvitationId: smsInvitation!.id },
                })
            );
        } catch (error) {
            handleError(error as Error);
        } finally {
            setUpdating(false);
        }
    }

    return (
        <div className={"smsSelfRegistration"}>
            {validationErrorMessage && <ValidationError message={validationErrorMessage} />}
            {updateError && (
                <ErrorView
                    error={updateError}
                    message={updateError instanceof RequestError ? updateError.body?.message : undefined}
                />
            )}
            {smsInvitation ? (
                smsInvitation.isPhoneNumberVerified ? (
                    <CompleteUserRegistration
                        onSubmit={handleCompleteRegistration}
                        onReset={() => setSmsInvitation(undefined)}
                        updating={updating}
                        smsInvitation={smsInvitation}
                    />
                ) : (
                    <VerifyPhoneNumber
                        smsInvitation={smsInvitation}
                        updating={updating}
                        resendCode={resendVerificationCode}
                        onSubmit={handleSubmitVerificationCode}
                        onReset={() => setSmsInvitation(undefined)}
                    />
                )
            ) : (
                <SearchSmsInvitation onSubmit={handleSubmitSearchSmsInvitation} updating={updating} />
            )}
            {updating && <Loader />}
        </div>
    );
}

function SearchSmsInvitation({
    onSubmit,
    updating,
}: {
    onSubmit(phonenumber: string): Promise<void>;
    updating: boolean;
}) {
    const [phonenumber, setPhonenumber] = useState("");

    return (
        <Form onSubmit={() => onSubmit(phonenumber)}>
            <h2>For deg som fått invitasjon - registrer bruker</h2>
            <FormPhone
                value={phonenumber}
                onChangeValue={setPhonenumber}
                label={"Ditt telefonnummer"}
                autoFocus={true}
            />
            <PrimaryButton type={"submit"} disabled={phonenumber.length < 4 || updating}>
                Neste
            </PrimaryButton>
        </Form>
    );
}

function VerifyPhoneNumber({
    smsInvitation,
    updating,
    resendCode,
    onSubmit,
    onReset,
}: {
    smsInvitation: SmsInvitationDto;
    updating: boolean;
    resendCode(): void;
    onSubmit(verificationCode: string): Promise<void>;
    onReset(): void;
}) {
    const [verificationCode, setVerificationCode] = useState("");

    return (
        <div className={"smsSelfRegistration-container"}>
            <Form onSubmit={() => onSubmit(verificationCode)} onReset={onReset} disabled={updating}>
                <h2>Verifiser telefonnummer</h2>
                <p>
                    Vi har sendt deg en kode på telefonnummer <strong>{smsInvitation.mobilePhone}</strong>
                </p>

                <FormInput
                    value={verificationCode}
                    onChangeValue={setVerificationCode}
                    label={"Skriv inn koden du mottok på SMS"}
                    autoFocus={true}
                />
                <div className={"submit-and-cancel"}>
                    <CancelButton disabled={updating} type={"reset"}>
                        Avbryt
                    </CancelButton>
                    <PrimaryButton disabled={updating} type={"submit"}>
                        Bekreft
                    </PrimaryButton>
                </div>
            </Form>
            <div>
                <p>Har du ikke mottatt noen kode?</p>
                <OutlinedPrimaryButton onClick={resendCode} disabled={updating}>
                    Send ny kode på SMS
                </OutlinedPrimaryButton>
            </div>
        </div>
    );
}

function CompleteUserRegistration({
    onSubmit,
    onReset,
    updating,
    smsInvitation,
}: {
    onSubmit: (smsInvitationRegistrationDto: SmsInvitationRegistrationDto) => Promise<void>;
    onReset: () => void;
    updating: boolean;
    smsInvitation: SmsInvitationDto;
}) {
    const [givenName, setGivenName] = useState(smsInvitation.givenName || "");
    const [familyName, setFamilyName] = useState(smsInvitation.familyName || "");
    const [email, setEmail] = useState(smsInvitation.emailAddress || "");

    function handleSubmit() {
        setGivenName(givenName.trim());
        setFamilyName(familyName.trim());
        setEmail(email.trim());
        if (email.length > 0 && familyName.length > 0 && givenName.length > 0 && !updating) {
            return onSubmit({ givenName, familyName, email });
        } else {
            throw new UserError("Alle feltene må fylles ut");
        }
    }

    return (
        <Form onSubmit={handleSubmit} disabled={updating} onReset={onReset}>
            <h2>Fullfør registrering</h2>
            <FormInput value={givenName} label={"Fornavn"} onChangeValue={setGivenName} autoFocus={true} />
            <FormInput value={familyName} label={"Etternavn"} onChangeValue={setFamilyName} />
            <FormEmail value={email} onChangeValue={setEmail} />
            <div className={"submit-and-cancel"}>
                <CancelButton disabled={updating} type={"reset"}>
                    Avbryt
                </CancelButton>
                <PrimaryButton
                    disabled={givenName.length < 1 || familyName.length < 1 || email.length < 1 || updating}
                    type={"submit"}
                >
                    Lagre
                </PrimaryButton>
            </div>
        </Form>
    );
}

function ValidationError({ message }: { message: string | JSX.Element }) {
    return (
        <div className="error-requestError">
            <WarningIcon size={"medium"} />
            <div>{message}</div>
        </div>
    );
}
