import * as React from "react";
import { FC } from "react";
import { ApplicationDto, UserInfoDto } from "./generated";
import { LogoIcon, sortBy } from "@bw/bw-components";
import { OutlinedPrimaryButton, PrimaryButton } from "./lib/buttons";
import { SmsSelfRegistration } from "./userManager/smsSelfRegistration";

export function ApplicationSelect({ userinfo }: { userinfo: UserInfoDto }) {
    const { availableApplications, availableOrganizations, availableIdentityProviders, clientApplication, personId } =
        userinfo;

    if (
        !personId ||
        (clientApplication &&
            !availableApplications.find((a) => a.name === clientApplication.name) &&
            clientApplication.smsInvitationAllowed)
    ) {
        return <SmsSelfRegistration userinfo={userinfo} />;
    }

    if (!availableOrganizations.length && availableIdentityProviders.length > 0) {
        return (
            <>
                <h1>Vi kjente deg ikke igjen</h1>
                <p>Knytt din ID-porten bruker til din emailadresse.</p>
                {availableIdentityProviders.map(({ name, label }) => (
                    <form key={name}>
                        <PrimaryButton onClick={(e) => goto("/api/login/start?idp=" + name, e)}>{label}</PrimaryButton>
                    </form>
                ))}
            </>
        );
    }

    if (!availableApplications.length || !availableOrganizations.length) {
        return (
            <div className="main">
                <h1>Ikke tilgang</h1>
                <p>
                    Du har ikke tilgang til BarentsWatch sine applikasjoner. Dersom du mener dette er feil, snakk med
                    din leder.
                </p>
            </div>
        );
    }

    return (
        <>
            <LogoIcon className="logo" />
            <div className={"bw-header"}>
                <div>Velg applikasjon</div>
            </div>
            <div className={"button-group"}>
                {availableApplications
                    .sort(sortBy("nb-NO", (app) => app.title))
                    .filter((app) => app.landingPage && app.showInApplicationSelector)
                    .map((app) => (
                        <ApplicationOption app={app} key={app.name} />
                    ))}
            </div>
            {false && (
                <>
                    <h1>Savner du en applikasjon?</h1>
                    {availableIdentityProviders.map(({ name, label }) => (
                        <form key={name}>
                            <button className={"selectButton"} onClick={(e) => goto("/login/start?idp=" + name, e)}>
                                {label}
                            </button>
                        </form>
                    ))}
                </>
            )}
        </>
    );
}

const goto = (landingPage: string, event: React.MouseEvent) => {
    event.preventDefault();
    window.location.href = landingPage;
};

const ApplicationOption: FC<{
    app: ApplicationDto;
}> = ({ app }) => {
    return (
        <form>
            <OutlinedPrimaryButton onClick={(event) => goto(app.landingPage, event)}>{app.title}</OutlinedPrimaryButton>
        </form>
    );
};
